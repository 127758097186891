<template>
  <router-view />
</template>

<script>
import {
  CREATING_COVER_LETTER_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";
import LetterHash from "@/helpers/cover-letter-hash";
import AppToast from "@/helpers/toast-methods";
import CompletedTemplate from "@/helpers/completed-template-hash";

export default {
  name: "CreationCoverLetter",

  beforeRouteEnter(to, from, next) {
    if (!to.meta?.isCreationStep) {
      next(
        `${ROUT_PATH.CREATING_COVER_LETTER_PATH.COVER_LETTER_CREATION}/${ROUT_PATH.CREATING_COVER_LETTER_PATH.STEP_INIT_LETTER}`
      );
    }

    if (
      to.name === CREATING_COVER_LETTER_NAMES.STEP_FINAL &&
      !CompletedTemplate.get()
    ) {
      next(ROUT_PATH.BROWS_RESUME_TEMPLATES);
      AppToast.toastError("No cover letter for saving...");
      return;
    }

    if (
      to.name !== CREATING_COVER_LETTER_NAMES.STEP_INIT_LETTER &&
      !LetterHash.get()
    ) {
      if (
        to.name === CREATING_COVER_LETTER_NAMES.STEP_FINAL &&
        !!CompletedTemplate.get()
      ) {
        next();
        return;
      }

      next(ROUT_PATH.BROWS_LETTERS_TEMPLATES);
      AppToast.toastError("No cover letter for editing...");
    }

    next();
  },
};
</script>

<style scoped></style>
